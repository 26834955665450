<template>
  <ui-component-modal
    :modalTitle="$t('Components.Terms.ModalEditCancelRules.Main_Title')"
    modalSize="large"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="updateCancelRules"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template slot="modalTitle"></template>
    <template v-slot:content>
      <div class="tabs is-boxed">
        <ul>
          <li
            v-for="meetingType in channel.Meetingtypes"
            @click="setSelectedMeetingtypeId(meetingType.MeetingtypeId)"
            :class="{
              'is-active': meetingType.MeetingtypeId === selectedMeetingtypeId,
            }"
            :key="meetingType.MeetingtypeId"
          >
            <a>{{ meetingType.MeetingtypeId | getMeetingtypeName }}</a>
          </li>
        </ul>
      </div>

      <div
        v-if="selectedMeetingtypeId !== 2"
        class="row is-flex is-align-items-center has-margin-bottom-x2"
      >
        <div class="has-text-weight-bold mr-2">
          {{ $t('Components.Terms.ModalEditCancelRules.Label_GroupSize') }}
        </div>
        <div class="tabs is-small is-toggle">
          <ul>
            <li :class="{ 'is-active': UtiliseGroupSize }">
              <a @click="setGroupSize(true)">
                <span>{{ $t('Form.Control.Yes') }}</span>
              </a>
            </li>
            <li :class="{ 'is-active': !UtiliseGroupSize }">
              <a @click="setGroupSize(false)">
                <span>{{ $t('Form.Control.No') }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="columns">
        <div class="column is-half has-padding" v-if="UtiliseGroupSize">
          <p class="subtitle is-size-4">
            {{
              $t('Components.Terms.ModalEditCancelRules.CardTitle_GroupSize')
            }}
          </p>
          <div class="card">
            <div class="card-content">
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th>
                      {{
                        $t(
                          'Components.Terms.ModalEditCancelRules.TableHeader_MinSeats'
                        )
                      }}
                    </th>
                    <th>
                      {{
                        $t(
                          'Components.Terms.ModalEditCancelRules.TableHeader_MaxSeats'
                        )
                      }}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  name="fade"
                  is="transition-group"
                  mode="out-in"
                  :duration="{ enter: 300, leave: 300 }"
                >
                  <tr
                    v-for="(groupSize, index) in groupSizes"
                    :key="index"
                    :class="{
                      'has-background-link is-selected':
                        groupSize.Id === selectedGroupSizeId,
                    }"
                  >
                    <td>
                      <input
                        style="width: 100px"
                        type="text"
                        class="input is-small"
                        v-model="groupSize.MinSeats"
                      />
                    </td>
                    <td>
                      <input
                        style="width: 100px"
                        type="text"
                        class="input is-small"
                        v-model="groupSize.MaxSeats"
                      />
                    </td>
                    <td>
                      <a
                        class="button is-small is-info is-outlined mr-2"
                        @click="setSelectedGroupSize(groupSize)"
                      >
                        {{
                          $t(
                            'Components.Terms.ModalEditCancelRules.Button_ShowRules'
                          )
                        }}
                      </a>

                      <a @click="deleteGroupSize(groupSize)">
                        <span class="icon">
                          <font-awesome-icon
                            :icon="['fas', 'trash-alt']"
                            style="color: red"
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="6">
                      <a
                        @click="addGroupSize"
                        class="button is-small is-success"
                      >
                        {{
                          $t(
                            'Components.Terms.ModalEditCancelRules.Button_AddGroupSize'
                          )
                        }}
                      </a>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>

        <div
          :class="[
            !UtiliseGroupSize ? 'column is-full' : 'column is-half',
            'has-padding',
          ]"
        >
          <p class="subtitle is-size-4">
            {{
              $t('Components.Terms.ModalEditCancelRules.CardTitle_CancelRules')
            }}
            <span
              v-if="selectedGroupSize && UtiliseGroupSize"
              class="subtile is-size-6 ml-1"
            >
              <em
                >{{
                  $t('Components.Terms.ModalEditCancelRules.Text_ForGroupSize')
                }}
                {{
                  `${selectedGroupSize.MinSeats} - ${selectedGroupSize.MaxSeats}`
                }}</em
              >
            </span>
          </p>
          <div class="card">
            <div class="card-content">
              <table class="table is-fullwidth is-striped is-hoverable">
                <thead>
                  <tr>
                    <th>
                      {{
                        $t(
                          'Components.Terms.ModalEditCancelRules.TableHeader_DaysTillBooking'
                        )
                      }}
                    </th>
                    <th>
                      {{
                        $t(
                          'Components.Terms.ModalEditCancelRules.TableHeader_Percentage'
                        )
                      }}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody
                  name="fade"
                  is="transition-group"
                  mode="out-in"
                  :duration="{ enter: 300, leave: 300 }"
                >
                  <tr v-if="showMessageNoRules" key="NoConfig">
                    <td colspan="6">
                      <article class="message is-warning mb-2 mt-2">
                        <div class="message-body">
                          {{
                            $t(
                              'Components.Terms.ModalEditCancelRules.Message_Warning'
                            )
                          }}
                        </div>
                      </article>
                    </td>
                  </tr>
                  <tr
                    v-for="(cancelRule, index) in filteredBookingTerm"
                    :key="index"
                  >
                    <td
                      v-if="cancelRule.MeetingtypeId === selectedMeetingtypeId"
                    >
                      <input
                        style="width: 100px"
                        type="text"
                        class="input is-small"
                        v-model="cancelRule.HoursUntilStart"
                      />
                    </td>
                    <td
                      v-if="cancelRule.MeetingtypeId === selectedMeetingtypeId"
                    >
                      <div class="field has-addons">
                        <p class="control">
                          <input
                            style="width: 75px"
                            type="text"
                            class="input is-small"
                            v-model="cancelRule.Percentage"
                          />
                        </p>
                        <p class="control">
                          <a class="button is-small is-static">%</a>
                        </p>
                      </div>
                    </td>
                    <td
                      v-if="cancelRule.MeetingtypeId === selectedMeetingtypeId"
                    >
                      <a @click="deleteCancelRule(cancelRule)">
                        <span class="icon">
                          <font-awesome-icon
                            :icon="['fas', 'trash-alt']"
                            style="color: red"
                          />
                        </span>
                      </a>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="6">
                      <a
                        @click="addCancelRule"
                        class="button is-small is-success"
                      >
                        {{
                          $t(
                            'Components.Terms.ModalEditCancelRules.Button_AddRule'
                          )
                        }}
                      </a>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ui-component-modal>
</template>

<script>
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
import { EventBus } from '@/eventbus/event-bus'
import termsProvider from '@/providers/terms'

export default {
  components: {},

  props: {
    showModal: {
      type: Boolean,
      default: false,
    },

    onClickCancel: {
      type: Function,
      required: true,
    },

    term: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingSuccess: false,
      isSavingError: false,
      selectedMeetingtype: null,
      selectedMeetingtypeId: 1,
      mTerm: {},
      originalBookingTerm: {},
      UtiliseGroupSize: null,
      groupSizes: [],
      selectedGroupSize: null,
    }
  },

  computed: {
    ...mapState('channelStore', ['channel']),

    showMessageNoRules() {
      let typeIndex = this.mTerm.CancelRules.findIndex(
        (rule) => rule.MeetingtypeId === this.selectedMeetingtypeId
      )
      if (typeIndex === -1 || this.mTerm.CancelRules.length === 0) {
        return true
      }
      return false
    },

    selectedGroupSizeId() {
      let result = 0

      if (this.selectedGroupSize) {
        result = this.selectedGroupSize.Id
      }

      return result
    },

    filteredBookingTerm() {
      let result = this.mTerm

      if (this.selectedGroupSize && this.UtiliseGroupSize) {
        result = result.CancelRules.filter(
          (rule) =>
            rule.MinSeats === this.selectedGroupSize.MinSeats &&
            rule.MaxSeats === this.selectedGroupSize.MaxSeats
        )
        return result
      } else {
        return result.CancelRules
      }
    },
  },

  created() {
    if (this.term) {
      this.mTerm = JSON.parse(JSON.stringify(this.term))
      this.mTerm.CancelRules.map(
        (rule) => (rule.HoursUntilStart = Math.floor(rule.HoursUntilStart / 24))
      )

      this.mTerm.CancelRules.map((rule) =>
        rule.MaxSeats > -1 || rule.MinSeats > -1
          ? this.groupSizes.push({
              Id: rule.Id,
              MinSeats: rule.MinSeats,
              MaxSeats: rule.MaxSeats,
            })
          : null
      )

      this.groupSizes = this.groupSizes.filter(
        (groupSizes, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.MinSeats === groupSizes.MinSeats &&
              t.MaxSeats === groupSizes.MaxSeats
          )
      )
      this.originalBookingTerm = { ...this.mTerm }

      if (this.groupSizes.length > 0) {
        this.groupSizes.map((size, index) => (size.Id = index + 1))
        this.UtiliseGroupSize = true
        this.setSelectedGroupSize(this.groupSizes[0])
      }
    }
  },

  methods: {
    ...mapMutations('termStore', ['setTerm']),

    updateCancelRules() {
      let self = this

      if (!self.isSaving) {
        self.isSaving = true
        this.mTerm.CancelRules.map(
          (rule) =>
            (rule.HoursUntilStart = Math.floor(rule.HoursUntilStart * 24))
        )

        termsProvider.methods
          .updateBookingTerm(self.mTerm)
          .then((response) => {
            if (response.status === 200) {
              this.setTerm(response.data)

              self.onClickCancel()
            }
          })
          .catch((error) => {
            //console.log(error.response)
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    setSelectedMeetingtypeId(meetingtypeId) {
      let self = this
      self.selectedMeetingtype = null
      self.selectedMeetingtypeId = meetingtypeId

      let meetingtypeIndex = self.channel.Meetingtypes.findIndex(
        (m) => m.MeetingtypeId === meetingtypeId
      )
      if (meetingtypeIndex > -1) {
        self.selectedMeetingtype = self.channel.Meetingtypes[meetingtypeIndex]
      }

      if (self.selectedMeetingtypeId === 2) {
        self.UtiliseGroupSize = false
        this.selectedGroupSize = null
      }
    },

    addCancelRule() {
      this.mTerm.CancelRules.push({
        // TermsId: this.mTerm.Id,
        MeetingtypeId: this.selectedMeetingtypeId,
        MinSeats: this.selectedGroupSize ? this.selectedGroupSize.MinSeats : -1,
        MaxSeats: this.selectedGroupSize ? this.selectedGroupSize.MaxSeats : -1,
        HoursUntilStart: 0,
        Percentage: 0,
      })
    },

    addGroupSize() {
      this.groupSizes.push({
        Id: this.groupSizes.length + 1,
        MinSeats: 0,
        MaxSeats: 0,
      })
      this.selectedGroupSize = this.groupSizes[this.groupSizes.length - 1]
    },

    deleteCancelRule(cancelRule) {
      let ruleIndex = this.mTerm.CancelRules.findIndex(
        (rule) => rule.Id === cancelRule.Id
      )

      if (ruleIndex > -1) {
        Vue.delete(this.mTerm.CancelRules, ruleIndex)
      }
    },

    deleteGroupSize(groupSize) {
      this.mTerm.CancelRules = this.mTerm.CancelRules.filter(
        (rule) =>
          rule.MinSeats !== groupSize.MinSeats &&
          rule.MaxSeats !== groupSize.MaxSeats
      )

      let groupIndex = this.groupSizes.findIndex(
        (group) =>
          group.MinSeats === groupSize.MinSeats &&
          group.MaxSeats === groupSize.MaxSeats
      )

      if (groupIndex > -1) {
        Vue.delete(this.groupSizes, groupIndex)
      }
      this.selectedGroupSize = this.groupSizes[this.groupSizes.length - 1]
    },

    setGroupSize(trueOrFalse) {
      this.UtiliseGroupSize = trueOrFalse
      // if statements ensure that the original data is restored when toggling between group utilise yes/no
      if (this.originalBookingTerm.CancelRules.length > 0) {
        if (
          !this.UtiliseGroupSize &&
          this.originalBookingTerm.CancelRules[0].MaxSeats > -1
        ) {
          this.mTerm.CancelRules = []
          this.selectedGroupSize = null
        } else if (
          !this.UtiliseGroupSize &&
          this.originalBookingTerm.CancelRules[0].MaxSeats === -1
        ) {
          this.mTerm = { ...this.originalBookingTerm }
        } else if (
          this.UtiliseGroupSize &&
          this.originalBookingTerm.CancelRules[0].MaxSeats > -1
        ) {
          this.mTerm = { ...this.originalBookingTerm }
          this.selectedGroupSize = this.groupSizes[0]
        } else if (
          this.UtiliseGroupSize &&
          this.originalBookingTerm.CancelRules[0].MaxSeats === -1
        ) {
          this.mTerm.CancelRules = []
          this.selectedGroupSize = null
        }
      }
    },

    setSelectedGroupSize(groupSize) {
      this.selectedGroupSize = groupSize
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/template';
.columnWrapper {
  border: 1px solid $grey-lighter;
  overflow-y: scroll;
  width: 100%;
}
</style>
